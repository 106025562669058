<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-4"
        dark
        color="blue"
        v-bind="attrs"
        v-on="on"
        @click="getClientes"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nueva
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nueva contratacion
      </v-card-title>
      <v-card-text>
        <v-menu
          ref="menu"
          v-model="fecha"
          :close-on-content-click="false"
          :return-value.sync="nuevaContratacion.fecha"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="nuevaContratacion.fecha"
              label="Fecha de la contratación"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="nuevaContratacion.fecha" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="fecha = false">
              Cancelar
            </v-btn>
            <v-btn text color="primary" @click="nuevaContratacion.fecha = ''">
              Limpiar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(nuevaContratacion.fecha)"
            >
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-autocomplete
          v-model="nuevaContratacion.clientes"
          :items="clientes"
          label="Clientes"
          multiple
          clearable
        ></v-autocomplete>

        <v-text-field
          label="Observaciones"
          v-model="nuevaContratacion.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      toggleDialog: false,
      fecha: false,
      clientes: [],
      nuevaContratacion: {
        fecha: '',
        clientes: '',
        observaciones: '',
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.loading = true;

        const res = await axios.post(
          process.env.VUE_APP_API_URL + '/contrataciones/nuevo',
          this.nuevaContratacion
        );

        this.loading = false;

        this.toggleDialog = false;

        this.$router.push({
          name: 'Contratacion',
          params: { id: res.data._id },
        });
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getClientes() {
      try {
        let clientes = await axios.get(
          process.env.VUE_APP_API_URL + '/clientes'
        );
        clientes = clientes.data;

        for (let i = 0; i < clientes.length; i++) {
          this.clientes.push(
            `${clientes[i].nombre} ${clientes[i].primerApellido} ${clientes[i].segundoApellido}`
          );
        }
      } catch (error) {
        alert('Error - No se pueden obtener los clientes');

        this.toggleDialog = false;
      }
    },
  },
};
</script>
